export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "program_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program detail"])},
      "Request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "Confirm cancellation?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm cancellation?"])},
      "request_collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Collection"])},
      "Online collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online collection"])},
      "Offline collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline collection"])},
      "Request Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Date"])},
      "Recycle request record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recycle request record"])},
      "Logistics number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics number"])},
      "Arrival Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival Date"])},
      "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
    }
  })
}
