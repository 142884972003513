export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "page desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 3년 동안 참여 기록을 확인할 수 있습니다."])},
      "No collection history record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 신청 내역이 없습니다"])}
    }
  })
}
