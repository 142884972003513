export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Participation date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加日"])},
      "close_confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このプログラムから退会することを確認する"])},
      "Re-participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再参加"])},
      "re_confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このプログラムに再参加することを確認する"])},
      "program_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳しくは"])},
      "collection_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リサイクルチャレンジ"])}
    }
  })
}
