import Kuroshiro from 'kuroshiro';
import { loadScript } from 'vue-plugin-load-script';
import * as hangul from 'hangul-js';
import _ from 'lodash';

export function useMultiLanguageSorter(local: string, key?: string) {
  // 这份文件install后有问题，需要调整webpack配置，不如直接引入方便
  async function init() {
    if (local === 'ja') {
      await loadScript(process.env.BASE_URL + 'kuroshiro-analyzer-kuromoji.min.js');
    }
  }
  const sortKey = key || 'title';

  async function sortJapanese(items: any, currentSortKey?: string) {
    await init();
    const kuroshiro = new Kuroshiro();
    kuroshiro.init(new window.KuromojiAnalyzer());
    const itemsWithRomaji = await Promise.all(
      items.map(async (item: any) => {
        const value = _.get(item, currentSortKey || sortKey);
        let romaji = value;
        try {
          romaji = await kuroshiro.convert(value, { to: 'romaji' });
        } finally {
          return {
            ...item,
            romaji
          };
        }
      })
    );
    return itemsWithRomaji.sort((a, b) => a.romaji!.localeCompare(b.romaji!, 'ja'));
  }

  function sortKorean(items: any, currentSortKey?: string) {
    return [...items].sort((a, b) => {
      const aValue = _.get(a, currentSortKey || sortKey);
      const bValue = _.get(b, currentSortKey || sortKey);
      const comparison = hangul.search(aValue, bValue);
      if (comparison === 0) {
        return aValue.localeCompare(bValue, 'ko');
      }
      return comparison;
    });
  }

  async function sortLanguage(items: any, currentSortKey?: string) {
    if (local === 'ja') {
      return sortJapanese(items, currentSortKey);
    }
    return sortKorean(items, currentSortKey);
  }

  return {
    sortLanguage,
    sortJapanese,
    sortKorean,
  };
}
