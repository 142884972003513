export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "offline_point_search_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매장명, 동명, 도로명을 검색해주세요."])},
      "sort_by_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름순"])},
      "sort_by_distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거리순"])}
    }
  })
}
