import { IProgram } from '@/types/program';

// 详情接口
export interface ProgramDetail {
  id: number | string;
  state: string;
  type: string;
  created_at: string;
  // TODO name后端返回拼接后的
  address: string;
  people_collection: string;
  human_program_round_time_range: string;
  target: {
    id: string;
    slug: string;
    name: string;
    joined_members_count: string;
    type: string;
    started_at: string;
    custom_campaign_domain?: string,
    image: {
      url: string;
    };
  };
}

// reactive初始化使用 不然报错太多，暂定这样处理
export const defaultProgramDetail: ProgramDetail = {
  id: '',
  state: '',
  type: '',
  created_at: '',
  address: '',
  people_collection: '',
  human_program_round_time_range: '',
  target: {
    id: '',
    name: '',
    slug: '',
    joined_members_count: '',
    type: '',
    started_at: '',
    image: {
      url: ''
    }
  }
};

// Program数据类型 后期可调整全局
export type Program = {
  id: number;
  created_at: string;
  type: string;
  show_cancel_btn: boolean;
  show_request_again_btn: boolean;
  total_weight: string;
  program_shipment: {
    weight: number;
    waste_collected_unit: number;
    waste_collected_qty: number;
    tracking_number: number;
    state: string;
    received_at: string;
    points_earned: number;
    no: string;
    created_at: string;
    quantity: number;
    unmatched: boolean;
    note1?: string;
    note2?: string;
  },
  user_request_program_shipment: {
    id: number;
    note1: string | null;
    note2: string | null;
    state: string;
    void_reason: string | null;
  }
}

// store后端返回数据类型
export type RecordItemType = {
  id: string | number;
  state: string;
  created_at: string;
  people_collection: string;
  target: IProgram;
}
