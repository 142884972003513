import { request } from '@/utils';
import { Collection } from './helper/collection';

interface Query {
  page?: number;
  keyword?: string;
  state_eq?: number | string;
  order_by?: string;
  program_shipments_state_in?: string[];
}

export class UserRequestStore extends Collection {
  private typeEq: string = '';
  private perPage: number = 6;
  private showCollectionRequests: boolean = false;

  public query: Query = {};
  constructor(options: { type_eq: string, per_page: number, show_collection_requests?: boolean }) {
    super();
    this.typeEq = options.type_eq;
    this.perPage = options.per_page;
    this.showCollectionRequests = options.show_collection_requests || false;
  }
  async fetch(params: Record<string, any>) {
    Object.assign(params, { ...this.query, type_eq: this.typeEq, per_page: this.perPage });
    if (this.showCollectionRequests) {
      params.show_collection_requests = true;
    }
    return request.get('/user_requests', { params });
  }

  updateQuery(query: Query) {
    this.query = query;
  }
}

export const userParticipationRecordStore = new UserRequestStore({ type_eq: 'ParticipationRequest', per_page: 6 });
export const userCollectionRequestsStore = new UserRequestStore({ type_eq: 'ParticipationRequest', per_page: 6, show_collection_requests: true });

