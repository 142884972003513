export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to collection history"])},
      "Application Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Date"])},
      "Application Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Status"])},
      "Offline Collection Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline Collection Information"])},
      "Date of Colletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Colletion"])},
      "Location Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Name"])},
      "Location Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Address"])},
      "Rewards Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rewards Type"])},
      "Rewards Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rewards Status"])},
      "View Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Rewards"])},
      "Earned Points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earned Points"])},
      "Barcode of the Collected Items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcode of the Collected Items"])},
      "View": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])}
    }
  })
}
