export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "page desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can review your participation records for up to the last three years."])},
      "No collection history record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No collection history record"])}
    }
  })
}
