export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Recent participating campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent participating campaigns"])},
      "Recent collection records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent collection records"])},
      "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more >"])}
    }
  })
}
