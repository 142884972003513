export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "program_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳しくは"])},
      "Request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集荷依頼をする"])},
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集荷依頼をキャンセル"])},
      "Confirm cancellation?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルしますか？"])},
      "request_collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回收物を送る(集荷依賴)"])},
      "Online collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンライン 集荷"])},
      "Offline collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オフライン 集荷"])},
      "Request Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集荷依頼日"])},
      "Recycle request record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リサイクル記録"])},
      "Logistics number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["運送番号"])},
      "Arrival Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到着予定日"])},
      "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重量"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステータス"])}
    }
  })
}
