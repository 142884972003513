export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "Recent participating campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去3年間の参加記録を確認できます。"])},
      "Recent collection records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集荷履歴がありません"])},
      "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더보기 >"])}
    }
  })
}
