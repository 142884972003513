export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["컬렉션 내역으로 돌아가기"])},
      "Application Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청 날짜"])},
      "Application Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청 현황"])},
      "Offline Collection Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오프라인 수거 정보"])},
      "Date of Colletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거일"])},
      "Location Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치"])},
      "Location Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치 주소"])},
      "Rewards Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리워드 유형"])},
      "Rewards Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리워드 현황"])},
      "View Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리워드 보기"])},
      "Earned Points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["획득 포인트"])},
      "Barcode of the Collected Items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 품목 바코드"])},
      "View": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보기"])}
    }
  })
}
