export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "Participation date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation date"])},
      "close_confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave this program?"])},
      "Re-participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-participation"])},
      "re_confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to re-participate in this program?"])},
      "program_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program detail"])},
      "collection_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection challenge"])}
    }
  })
}
