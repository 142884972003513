import { isMobile } from '@/utils';
import { onMounted, onUnmounted } from 'vue';
import _ from 'lodash';

export function useMobileScrollMore(callback: () => void) {
  // 移动端 滚动加载更多
  const handleScroll = _.debounce(async () => {
    if (!isMobile()) {
      return;
    }
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 250) {
      callback();
    }
  }, 200);

  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });
  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });
}
