export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "page desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去3年間の参加記録を確認できます。"])},
      "No collection history record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集荷履歴がありません"])}
    }
  })
}
