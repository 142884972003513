export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Participation date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여 날짜"])},
      "close_confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여하고 계신\n재활용 캠페인을 취소하시겠습니까?\n\n취소할 경우,\n다시 참여 신청이 어려울 수 있습니다"])},
      "Re-participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재참여"])},
      "re_confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여하고 계신\n재활용 캠페인에 다시 참여하시겠습니까?"])},
      "program_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캠페인 참여 내역으로 돌아가기"])},
      "collection_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재활용 챌린지"])}
    }
  })
}
