export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "program_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캠페인 참여 내역으로 돌아가기"])},
      "Request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 신청"])},
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
      "Confirm cancellation?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소 하시겠습니까?"])},
      "request_collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거신청"])},
      "Online collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["온라인 수거"])},
      "Offline collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오프라인 수거"])},
      "Request Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수거 신청일"])},
      "Recycle request record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재활용 신청 내역"])},
      "Logistics number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운송장 번호"])},
      "Arrival Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도착 예정일"])},
      "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무게"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태"])}
    }
  })
}
