import { Menu } from '@/types/menu';
import i18n from '@/i18n';
import urlJoin from 'url-join';

const t = i18n.global.t;
const { VUE_APP_ENV, VUE_APP_COUNTRY } = process.env;

export const CallbackSessionKey = 'callback_session_key';
export const CallbackOriginSessionKey = 'callback_origin_session_key';

// enum
export enum ColorVariants {
  primary = 'primary',
  secondary = 'secondary',
  success = 'success',
  danger = 'danger',
  warning = 'warning',
  info = 'info',
  light = 'light',
  dark = 'dark',
  outlinePrimary = 'outline-primary',
  outlineSecondary = 'outline-secondary',
  outlineSuccess = 'outline-success',
  outlineDanger = 'outline-danger',
  outlineWarning = 'outline-warning',
  outlineInfo = 'outline-info',
  outlineLight = 'outline-light',
  outlineDark = 'outline-dark',
}

// const
export const countryList = [
  {
    key: 'au',
    icon: require('@/assets/flag/australia.png'),
    text: 'Australia',
    link: '',
  },
  {
    key: 'ja',
    icon: require('@/assets/flag/japan.png'),
    text: '日本',
    link: {
      staging: 'https://staging.terracycle-jp.com',
      // https://www.terracycle-jp.com
      production: ''
    }[VUE_APP_ENV],
  },
  {
    key: 'ko',
    icon: require('@/assets/flag/south-korea.png'),
    text: '한국',
    link: {
      staging: 'https://tc-mini-site.beansmile-dev.com',
      production: 'https://www.terracycle-kr.com'
    }[VUE_APP_ENV],
  },
  {
    key: 'nz',
    icon: require('@/assets/flag/new-zealand.png'),
    text: 'New Zealand',
    link: '',
  },
].filter(item => item.link);

export const currentCountry = countryList.find(({ key }) => key === process.env.VUE_APP_COUNTRY) || countryList[0];

export const currentI18nLocale = {
  ko: 'ko', ja: 'ja', au: 'en', nz: 'en'
}[process.env.VUE_APP_COUNTRY] || 'en';

export const USER_REQUEST_STATE_MAP = {
  pending: t('Pending'),
  approved: t('Approved'),
  rejected: t('Rejected'),
  closed: t('Closed'),
  wait_listed: t('wait_listed'),
  not_ongoing: t('Not ongoing'),
};

// https://mattermost.beansmile-dev.com/beansmile/pl/qjfddiugm3dqdf43sxstradopc
export const SHIPMENT_STATE_MAP: {[propName: string]: string} = currentI18nLocale === 'ja' ? {
  init: '集荷依頼済み',
  pending: '集荷中/輸送中',
  received: '集荷中/輸送中',
  logistic_cancelled: '集荷中/輸送中',
  tc_synced: '完了',
  cancelled: 'キャンセル済み',
  void: t('Void'),
} : {
  init: t('Request'),
  pending: t('Completed request'),
  waiting_pick_up: t('Completed request'),
  void: t('Void'),
  shiped: t('Delivery'),
  arrived: t('Arrival'),
  cancelled: t('Cancelled'),
  received: t('Inspection'),
  tc_synced: t('Recycle'),
  logistic_cancelled: t('Completed request')
};

export const IS_KO_PRODUCTON = VUE_APP_COUNTRY === 'ko' && VUE_APP_ENV === 'production';

export const PROGRAM_SHIPMENTS_STATE_LIST = currentI18nLocale === 'ja' ? [
  { name: SHIPMENT_STATE_MAP['init'], state: ['init'], value: 'init' },
  { name: SHIPMENT_STATE_MAP['pending'], state: ['pending', 'received', 'logistic_cancelled'], value: 'pending' },
  { name: SHIPMENT_STATE_MAP['tc_synced'], state: ['tc_synced'], value: 'tc_synced' },
  { name: SHIPMENT_STATE_MAP['cancelled'], state: ['cancelled'], value: 'cancelled' },
] : [
  { name: SHIPMENT_STATE_MAP['pending'], state: ['pending', 'waiting_pick_up'], value: 'pending' },
  { name: SHIPMENT_STATE_MAP['shiped'], state: ['shiped'], value: 'shiped' },
  { name: SHIPMENT_STATE_MAP['arrived'], state: ['arrived'], value: 'arrived' },
  { name: SHIPMENT_STATE_MAP['received'], state: ['received'], value: 'received' },
  { name: SHIPMENT_STATE_MAP['tc_synced'], state: ['tc_synced'], value: 'tc_synced' },
  { name: SHIPMENT_STATE_MAP['cancelled'], state: ['cancelled'], value: 'cancelled' },
];

// 全局菜单
export const MENUS = [
  {
    id: 'Program',
    name: t('Program'),
    to: { name: 'programs.all' },
    // children: [
    //   { id: 'viewsAllProgram', name: t('Views All Programs'), to: { name: 'programs.all' } },
    //   { id: 'contestsAndPromotions', name: t('Contests and Promotions'), to: '' },
    // ]
  },
  // {
  //   name: t('Shop'),
  //   to: { name: 'shop' },
  //   children: [
  //     { name: t('Zero Waste Box'), to: '' },
  //     { name: t('TC Select Shop'), to: '' },
  //   ]
  // },
  // 日韩prod都不显示
  VUE_APP_ENV !== 'production' && {
    id: 'story',
    name: t('Story'),
    to: { name: 'story.index' }
  },
  {
    id: 'about',
    name: t('About'),
    to: '',
    children: [
      { id: 'terraCycleIntro', name: t('TerraCycle Intro'), to: { name: 'terracycleIntro' } },
      process.env.VUE_APP_COUNTRY !== 'ja' ? { id: 'strategicPartners', name: t('Strategic Partners'), to: { name: 'strategicPartners' } } : undefined,
    ].filter(Boolean) as Menu[],
  },
  {
    id: 'contactUs',
    name: t('Contact Us'),
    to: { name: 'contactUs' },
  }
].filter(Boolean) as Menu[];

// 我的页面菜单
export const getMineMenus = () => {
  // 直接import会报一个奇怪的错误  可能是循环引用
  const { authStore } = require('@/stores');
  return [
    {
      id: 'memberProfile',
      name: t('Member profile'),
      to: { name: 'mine.memberProfile' }
    },
    !IS_KO_PRODUCTON && {
      id: 'mineTCPoint',
      name: t('TC Point'),
      to: { name: 'mine.tc_point' }
    },
    // {
    //   name: t('Membership grade'),
    //   to: { name: 'mine.membershipGrade' }
    // },
    { id: 'participationHistory', name: t('Participation history'), to: { name: 'mine.participationHistory' } },
    { id: 'collectionHistory', name: t('Collection History'), to: { name: 'mine.collectionHistory' } },
    authStore.user?.company_account?.role === 'store_sub' && {
      id: 'offlineCollections',
      name: t('Incentive Campaign List'),
      to: { name: 'mine.offline_collections' },
    },
    { id: 'myCoupons', name: t('My Coupon'), to: { name: 'mine.coupons' } },
    // {
    //   id: 'myProgram',
    //   name: t('My Program'),
    //   to: '',
    //   children: [
    //     { id: 'programParticipationRecord', name: t('Program participation record'), to: { name: 'mine.programParticipationRecord' } },
    //     { id: 'membershipIDwithQRcode', name: t('Membership ID with QR code'), to: { name: 'mine.membershipIdWithQrcode' } },
    //   ]
    // },
    // { name: t('Shop'), to: { name: 'mine.shop' } },
    // {
    //   id: 'shop',
    //   name: t('Shop'),
    //   to: '',
    //   children: [
    //     {
    //       id: 'wishList',
    //       name: t('Wish list'),
    //       to: { name: 'mine.shop.wishList' }
    //     },
    //     {
    //       id: 'myOrders',
    //       name: t('My orders'),
    //       to: { name: 'mine.shop.myOrders' }
    //     },
    //   ]
    // },
    { id: 'myQRCode', name: t('My QR code'), to: { name: 'mine.qrcode' } },
    authStore.user?.has_pdo && { id: 'myPdo', name: 'PDO', to: { name: 'pdo.programs' } }
  ].filter(Boolean) as Menu[];
};

export enum PEOPLE_COLLECTION {
  individual = 'individual',
  group = 'group_type',
};

export const PEOPLE_COLLECTION_MAP = {
  individual: t('Individual'),
  group_type: t('Group')
};

export const GENDER_MAP = {
  male: t('Male'),
  female: t('Female'),
  unknown: t('Prefer not to answer')
};

// https://developers.google.com/maps/documentation/javascript/localization
// TODO: 其他国家
const GOOGLE_MAP_LOCALE = {
  ko: {
    region: 'KR',
    language: 'ko'
  },
  ja: {
    region: 'JP',
    language: 'ja'
  }
}[VUE_APP_COUNTRY] || {};

export const GOOGLE_MAP_API_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}&region=${GOOGLE_MAP_LOCALE.region}&language=${GOOGLE_MAP_LOCALE.language}&libraries=geometry`;

export const YUBINBANGO_URL = urlJoin(process.env.BASE_URL, 'yubinbango.js');

export const CAPTCHA_RCODE_SESSION_KEY = `${VUE_APP_ENV}_CAPTCHA_RCODE_SESSION_KEY`;

export const OC_REWARD_TYPE = {
  points: t('offline_collection.oc_reward_type.points'),
  coupon: t('offline_collection.oc_reward_type.coupon'),
};

export const OC_REWARD_STATE = {
  init: t('offline_collection.oc_reward_state.init'),
  pending: t('offline_collection.oc_reward_state.pending'),
  approved: t('offline_collection.oc_reward_state.approved'),
  rejected: t('offline_collection.oc_reward_state.rejected'),
};

export const REDEMPTION_STATE = {
  requested: t('redemption.state.requested'),
  sent: t('redemption.state.sent'),
  cancelled: t('redemption.state.cancelled'),
};

export const USER_REQUEST_ORDER_BY_MAP = {
  program_name: t('user_request.order_by.program_name'),
  recent_participation: t('user_request.order_by.recent_participation'),
  recent_collection_request: t('user_request.order_by.recent_collection_request')
};
