import { request } from '@/utils';
import { SimpleStore } from './helper/simple-store';
import { IUser } from '@/types/user';
const { Storage } = require('@/utils/storage');
import router from '@/router';
import { CallbackSessionKey, CallbackOriginSessionKey } from '@/constants';
import _ from 'lodash';
import { Responses } from '@/types/responses';
import { AxiosRequestConfig } from 'axios';

interface Data {
  access_token: string;
  user: IUser;
  is_bind_sns?: boolean;
}

type passwordForm = {
  email: string
  password: string
}

type UpdatePhoneNumberForm = {
  phone_number: string,
  password: string,
  verification_code: string,
}

const ACCESS_TOKEN_KEY = `${process.env.VUE_APP_ENV}_USER_ACCESS_TOKEN_DATA`;

class AuthStore extends SimpleStore {
  constructor() {
    super();
    this.storage = new Storage(ACCESS_TOKEN_KEY);
  }
  storage: Storage
  user: IUser = {}
  $access_token: string = ''

  set access_token(v: string) {
    this.$access_token = v;
    this.storage.set(ACCESS_TOKEN_KEY, v);
  }

  get access_token(): string {
    this.$access_token = this.$access_token || this.storage.get(ACCESS_TOKEN_KEY) || '';
    return this.$access_token;
  }

  get isLogin() {
    return !!this.access_token;
  }

  get phoneNumber() {
    const phoneNumber = _.get(this.user, 'profile.phone_number');
    if (phoneNumber) {
      const list = phoneNumber.split(' ');
      if (list?.length) {
        return list.pop();
      }
    }
    return null;
  }

  get canParticipateProgram() {
    const { street_address, first_name, last_name, phone_number } = _.get(this.user, 'profile') || {};
    if (this.user?.is_dummy) {
      return Boolean(street_address && first_name && last_name);
    }
    return Boolean(street_address && first_name && last_name && phone_number);
  }

  get isB2() {
    return _.get(this.user, 'company_account.role') === 'store_sub';
  }

  async fetch() {
    const res = await request.get<IUser>('/mine');
    this.user = res.data;
    return res;
  }

  // 通过 sso_token 登录
  async loginSsoToken(ssoToken: string, requestConfig: AxiosRequestConfig<null> = {}) {
    const { data } = await request.post('auth/sso_auth', null, {
      headers: {
        'SSO-Authorization': ssoToken
      },
      ...requestConfig
    });
    this.access_token = data.access_token;
    return data;
  }

  signOut() {
    this.access_token = '';
    this.user = {};
    this.isFulfilled = false;
  }

  async updatePhoneNumber(body: UpdatePhoneNumberForm) {
    await request.post('mine/update_phone_number', body);
    if (this.user.profile?.phone_number) {
      this.user.profile.phone_number = body.phone_number;
    }
  }

  async signUp(body: any) {
    const { data } = await request.post('/auth/sign_up', body);
    this.access_token = data.access_token;
    this.user = data.user;
    return data;
  }

  // 刪除账户
  async delete(data: Responses) {
    await request.delete('mine', { data });
    this.signOut();
  }

  async login(api: string, code?: string): Promise<Data> {
    const route = router.currentRoute.value;
    code = code || (route.query.code as string);
    const { data } = await request.post(api, { code });
    this.access_token = data.access_token;
    this.user = data.user;
    return data;
  }

  async loginPassword(body: passwordForm) {
    const { data } = await request.post('auth/sign_in_via_password', body);
    this.access_token = data.access_token;
    this.user = data.user;
    return data;
  }

  async loginTc(code?: string): Promise<Data> {
    return this.login('/auth/terra_cycle/login', code);
  }

  async loginKakao(code?: string) {
    return this.login('/auth/kakao/login', code);
  }

  async loginNaver(code?: string) {
    return this.login('/auth/naver/login', code);
  }

  async loginGoogle(code?: string) {
    return this.login('/auth/google/login', code);
  }

  async loginLine(code?: string) {
    return this.login('/auth/line/login', code);
  }

  async goOauthLogin(api: string, redirect_url: string, options: { callback?: string, isSession?: boolean, callbackOrigin?: string } = {}) {
    let redirectUrl = `${process.env.VUE_APP_SITE_ORIGIN}${redirect_url}`;
    const route = router.currentRoute.value;
    let callbackPath = options.callback || route.query.callback || route.fullPath;
    let callbackOrigin = options.callbackOrigin || route.query.callback_origin || '';
    // 如果是登录页，就直接跳到首页
    if ((callbackPath as string).startsWith('/login')) {
      // 为空会直接跳到首页
      callbackPath = '';
    }
    if (options.isSession) {
      // 如果第三方授权不能带参数，那就使用 session 兼容一下
      sessionStorage.setItem(CallbackSessionKey, callbackPath as string);
      sessionStorage.setItem(CallbackOriginSessionKey, callbackOrigin as string);
    } else {
      redirectUrl += `?callback=${callbackPath}`;
    }
    const { data } = await request.get(api, {
      params: {
        // 无法添加本地的回调域名，本地开发回调先跳转到 staging
        redirect_url: redirectUrl
      }
    });
    location.href = data.authorize_url;
  }

  // 跳转到 terracycle 去登录授权
  async goTcOauth() {
    // tc oauth 登录不能带参数，否则登录会失败
    return this.goOauthLogin('auth/terra_cycle/authorize_url', '/login/callback_tc', { isSession: true });
  }

  // 第三方登录 kakao
  async goKakaoOauth() {
    // kakao oauth 登录不能带参数，否则登录会失败
    return this.goOauthLogin('auth/kakao/authorize_url', '/login/callback_kakao', { isSession: true });
  }

  // 第三方登录 Naver
  async goNaverOauth() {
    // Naver oauth 登录不能带参数，否则登录会失败
    return this.goOauthLogin('auth/naver/authorize_url', '/login/callback_naver', { isSession: true });
  }

  // 第三方登录 Google
 async goGoogleOauth() {
    // Google oauth 登录不能带参数，否则登录会失败
    return this.goOauthLogin('auth/google/authorize_url', '/login/callback_google', { isSession: true });
 }

  // 第三方登录 Line
  async goLineOauth() {
    // Line oauth 登录不能带参数，否则登录会失败
    return this.goOauthLogin('auth/line/authorize_url', '/login/callback_line', { isSession: true });
  }
}

export const authStore = AuthStore.create<AuthStore>();
