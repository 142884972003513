export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "offline_point_search_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拠点を検索する"])},
      "sort_by_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
      "sort_by_distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["距離"])}
    }
  })
}
