export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ko",
    "resource": {
      "Recent participating campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 참여 캠페인"])},
      "Recent collection records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 수거 기록"])},
      "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더보기 >"])}
    }
  })
}
