import { Collection } from './helper/collection';
import { isMobile, request } from '@/utils';

interface Query {
  page?: number;
  keyword?: string;
}

class ProgramStore extends Collection {
  query: Query = {}
  async fetch(params: any) {
    return request.get('/programs', { params: { ...params, ...this.query, per_page: isMobile() ? 8 : 9 } });
  }

  updateQuery(query: Query) {
    this.query = query;
  }
}

export const programStore = new ProgramStore();
